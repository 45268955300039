import React from 'react'
import three from '../imgaes/third-1.jpg'
import four from '../imgaes/pexels-pixabay-38544.jpg'
const FifthContainer = () => {
  return (
    <div className='mt-1'>
        <div className="w-full pb-14 | md:pb-16 | lg:pb-4 | overflow-x-hidden ">
            <div className="w-full px-2 | sm:px-10 | md:px-12 | xl:px-32 | 2xl:px-40 | 3xl:px-60 | 4xl:px-80">
                <div className="w-full flex flex-wrap">
                    <div className="w-full flex flex-col mt-48 justify-center relative px-2 order-2 | lg:order-2 lg:w-1/2">
                        <div className="w-full flex flex-col relative -ml-4 sm:-ml-10 md:-ml-12 lg:-ml-16">
                            <p className="text-xs font-poppins text-primary-600 pl-12 font-semibold leading-normal uppercase tracking-wide pb-12 | sm:pb-6">
                                Our Approach
                            </p>
                            <div className="transition text-primary-600 w-12 h-12 flex items-center justify-center  z-10 absolute top-2 left-0">
                                <svg className="h-full w-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 64"><path d="M11.997 58.473c-.183-.014-.366-.028-.549-.04-3.484-.25-6.968-.494-10.452-.752-.346-.026-.492.07-.491.425 0 .343-.01.692-.07 1.03-.076.43.058.594.503.67 2.529.431 5.066.834 7.573 1.37 2.934.626 5.84 1.38 8.758 2.078.164.039.33.07.563.12.005-.23.021-.41.01-.588-.359-5.48-.303-10.967-.053-16.457.038-.845.13-.765-.734-.88-1.289-.172-1.279-.173-1.452 1.122-.439 3.276-.887 6.55-1.333 9.826-.012.087-.04.172-.075.31-.104-.09-.178-.14-.233-.206-2.324-2.786-4.52-5.661-6.215-8.89C4.2 40.854 2.752 33.692 3.89 26.053c.92-6.18 3.427-11.709 7.268-16.662 2.086-2.69 4.51-5.043 7.175-7.156.55-.435.545-.442.11-.98-.243-.303-.5-.597-.73-.909-.242-.325-.456-.312-.774-.06-2.282 1.824-4.43 3.785-6.348 5.993-3.62 4.171-6.334 8.846-7.957 14.113C.017 28.883.469 37.206 3.788 45.35a40.15 40.15 0 0 0 5.178 9.096c.891 1.178 1.823 2.325 2.737 3.486.114.145.232.286.348.43l-.054.11Z" fill="currentColor"></path>
                                </svg>
                            </div>
                        </div>
                        <h2 className="text-2xl | xl:text-3xl font-poppins font-semibold text-primary-600 leading-tight tracking-tight mb-10 | lg:pr-2/12">
                            <span>If it isn’t data driven, it isn’t marketing
</span>
                        </h2>
                        <div className="w-full lg:px-2/12">
                            <div className="w-full mb-10">
                                <span className="font-poppins text-primary-600">Forget empty promises and fleeting trends. Our marketing goes beyond the hype, focusing on what truly matters: achieving your specific goals and objectives. We don't just create campaigns; we craft strategic blueprints designed for long-term success. We delve deep to understand your unique business, target audience, and desired outcomes. Then, we meticulously plan and execute campaigns that deliver measurable results.</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col order-1 pt-24 | lg:order-1 lg:w-1/2 lg:px-2">
                        <div className="w-full flex flex-wrap relative mb-8 | lg:mb-0">
                            <div className="hidden mb-20 h-[668px] w-105 mr-auto | lg:pr-10 lg:ml-28 | xl:ml-20 | 2xl:pr-0 |  lg:block">
                                <div className="relative overflow-hidden w-full pt-16">
                                    <img  data-sizes="100vw" alt="Lewis Camera" className="lazy-load h-[368px] w-full rounded-3xl js-lazy-load rounded-20 entered lazy-load--loaded" src={three}/>
                                </div>
                            </div>
                            
                            <div className="w-full absolute flex px-2 | lg:hidden">
                                <div className=" top-0 left-0 mr-4 p-4 overflow-hidden w-full " style={{paddingTop: '133.25%' }}>
                                    <picture className="absolute top-0 right-0 w-full h-full object-cover rounded-20">
                                    <source type="image/webp" data-srcset="https://co-andco.transforms.svdcdn.com/production/images/Lewis-Camera.jpg?w=400&amp;h=533&amp;q=80&amp;fm=webp&amp;fit=crop&amp;crop=focalpoint&amp;fp-x=0.5&amp;fp-y=0.5&amp;dm=1694531219&amp;s=fd72afd46d7cdbd691a041111cfbf89c 400w, https://co-andco.transforms.svdcdn.com/production/images/Lewis-Camera.jpg?w=800&amp;h=1067&amp;q=80&amp;fm=webp&amp;fit=crop&amp;crop=focalpoint&amp;fp-x=0.5&amp;fp-y=0.5&amp;dm=1694531219&amp;s=0b37ea728c1b969c3389e7aac9356c6b 800w, https://co-andco.transforms.svdcdn.com/production/images/Lewis-Camera.jpg?w=1200&amp;h=1600&amp;q=80&amp;fm=webp&amp;fit=crop&amp;crop=focalpoint&amp;fp-x=0.5&amp;fp-y=0.5&amp;dm=1694531219&amp;s=bb1c771c18f41e2e97c9eee6ce7866ef 1200w, https://co-andco.transforms.svdcdn.com/production/images/Lewis-Camera.jpg?w=1800&amp;h=2400&amp;q=80&amp;fm=webp&amp;fit=crop&amp;crop=focalpoint&amp;fp-x=0.5&amp;fp-y=0.5&amp;dm=1694531219&amp;s=8d063ff89e5dab64a79b31454b4f3a23 1800w" data-sizes="100vw"/>
                                    <img  alt="Lewis Camera" className="lazy-load js-lazy-load rounded-lg entered exited object-cover" src={four}/>
                                    </picture>
                                </div>
                            </div>
                        
                            <div className="hidden h-96 w-72 rounded-20 absolute bottom-0 transform  -left-52 xl:-left-60 | lg:block">
                                <div className="relative overflow-hidden w-full" >
                                    <img  alt="Harry and Sophie" className="w-fit h-[361px] lazy-load rounded-3xl js-lazy-load rounded-20 entered lazy-load--loaded" src={four} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FifthContainer