import React from 'react'

const MyComponent = ({data}) => {
  return (
    <div className='w-1/2 mx-1 | lg:mx-10 lg:w-1/2'>
        <img src={data} alt='pic' className='h-[80px] | lg:h-[100px]'  />
    </div>
  )
}

export default MyComponent