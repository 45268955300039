import './App.css';
import FirstContainer from './Components/FirstContainer';
import NewNavbar from './Responsive Navbar/NewNavbar';
import Own from './utils/Own';
import QueryContainer from './Components/QueryContainer';
import ThirdContainer from './Components/ThirdContainer';
import FifthContainer from './Components/FifthContainer';
import Partner from './Components/Partner';
import { Outlet } from "react-router-dom";
import Testimonial from './Components/Testimonial';
import NewFooter from './Responsive Navbar/NewFooter';
import ExtraFunc from './utils/ExtraFunc';
import MoreOne from './utils/MoreOne';
import MoreTwo from './utils/MoreTwo';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'
function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App">
      <NewNavbar/>
      <FirstContainer/>
      <Partner/>
      <ThirdContainer/>
      <MoreOne/>
      <MoreTwo/>
      <Own/>
      <Testimonial/>
      <FifthContainer/>
      <QueryContainer/>
      <ExtraFunc/>
      <NewFooter/>
      <Outlet/>
    </div>
  );
}

export default App;
