import React from 'react'
import Marquee from 'react-fast-marquee'

const ExtraFunc = () => {

  const hoverStyle = {
    transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
  };

  return (
    <div style={hoverStyle} className='bg-black flex items-center text-white hover:bg-white hover:text-black'>
      <Marquee direction="left" className='overflow-hidden' speed={50} delay={5}>
          <div className='w-full flex items-center h-full'>
              <p className='text-8xl font-semibold font-poppins'>Click. Convert. Conquer. Repeat. It's that simple.</p>
          </div>
          <div className='w-full flex px-2 items-center h-full'>
              <p className='text-8xl font-semibold font-poppins'>Click. Convert. Conquer. Repeat. It's that simple.</p>
          </div> 
      </Marquee>
    </div>
  )
}

export default ExtraFunc