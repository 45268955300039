import React from 'react'
import { starsvg } from '../utils/constant'
import one from '../imgaes/market-st.webp'
import two from '../imgaes/istockphoto-1500512548-170667a.webp'
import three from '../imgaes/Social media.jpg'
import five from '../imgaes/brand-pico.jpg'
import six from '../imgaes/lead-gener.webp'
import seven from '../imgaes/email-mar.webp'
import eight from '../imgaes/padi-ad.jpg'
import nine from '../imgaes/creative-de.webp'
import ten from '../imgaes/blog-ser.webp'
import eleven from '../imgaes/brand-cl.jpeg'
import { UilBolt } from '@iconscout/react-unicons'
import { UilBullseye } from '@iconscout/react-unicons'
import { UilRuler } from '@iconscout/react-unicons'
import { UilEnvelopeStar } from '@iconscout/react-unicons'
import { UilArrowGrowth } from '@iconscout/react-unicons'

const Own = () => {

  return (
    <div className='flex w-full flex-col pt-[12px]' id='services'>
        <div className='flex flex-col relative px-4 bg-black | lg:flex lg:flex-row lg:px-40'>
            <div class="relative w-full mt-24 | lg:w-1/2 lg:left-0">
                <div class="sticky top-0 h-96 bg-black ml-6 | lg:ml-0 ">
                    <p className='text-4xl text-white font-semibold mx-10 pt-2 font-poppins | lg:text-5xl lg:pt-20'>What we do</p>
                    <div className='pt-[40px] mx-7 | lg:mx-10'>
                    <a href="#_" class="ml-6 relative items-center justify-start inline-block px-3 py-1 overflow-hidden font-bold rounded-full group | lg:px-5 lg:py-3">
                    <span class="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
                    <span class="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-white opacity-100 group-hover:-translate-x-8"></span>
                    <span class="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-gray-900">Browse all our Services  </span>
                    <span class="absolute inset-0 border-2 border-white rounded-full"></span>
                    </a>
                    </div>
                </div>
            </div>
        <div class="relative w-full -mt-40 | lg:w-1/2 lg:mt-40">
            <div className="sticky top-[90px] h-[260px] w-full bg-white  rounded-3xl ">
                <div className='relative left-[2px] | lg:left-[26px]'>
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <svg className="svg-inline--fa fa-chalkboard-teacher text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z"></path></svg>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Marketing Strategy</p>
                        </h2>
                    </div>  
                </div>
                <img src={one} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] w-full bg-white  rounded-3xl ">
                <div className="w-full flex flex-wrap p-2 items-center justify-center my-4">
                    <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                        <svg className="svg-inline--fa fa-chalkboard-teacher text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                        <path fill="currentColor" d="M163.9 136.9c-29.4-29.8-29.4-78.2 0-108s77-29.8 106.4 0l17.7 18 17.7-18c29.4-29.8 77-29.8 106.4 0s29.4 78.2 0 108L310.5 240.1c-6.2 6.3-14.3 9.4-22.5 9.4s-16.3-3.1-22.5-9.4L163.9 136.9zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z"></path></svg>
                        <i class="fa-solid fa-fas fa-chalkboard-teacher text-white"></i>
                    </div>
                    <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                        <p>Engagement</p>
                    </h2>
                </div>  
                <img src={two} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] w-full bg-white  rounded-3xl ">
                <div className="w-full flex flex-wrap p-2 items-center justify-center my-4 | lg:relative lg:left-14">
                    <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                        <svg className="svg-inline--fa fa-chalkboard-teacher text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                        <path fill="currentColor" 
                        d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"></path></svg>
                        <i class="fa-solid fa-fas fa-chalkboard-teacher text-white"></i>
                    </div>
                    <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                        <p>Social Media Management</p>
                    </h2>
                </div>  
                <img src={three} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                    <div className="w-full flex flex-wrap py-2 items-center justify-center my-4 | lg:-ml-3">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <svg className="svg-inline--fa fa-chalkboard-teacher ml-[3px] text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                            <path fill="currentColor" 
                            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"></path></svg>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Branding</p>
                        </h2>
                </div>
                <img src={five} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4 | lg:relative lg:left-[26px]">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilEnvelopeStar color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>E-mail Marketing</p>
                        </h2>
                </div>
                <img src={seven} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4 | lg:relative lg:left-[40px]">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilArrowGrowth color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Paid Advertisements</p>
                        </h2>
                </div>
                <img src={eight} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4 | lg:relative lg:left-[23px]">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilBolt color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Lead Generation</p>
                        </h2>
                </div>
                <img src={six} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4 | lg:relative lg:left-[32px]">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <svg className="svg-inline--fa fa-chalkboard-teacher text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z"></path></svg>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Brand Collboration</p>
                        </h2>
                </div>
                <img src={eleven} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4 | lg:relative lg:left-[12px]">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilBullseye color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Blog Services</p>
                        </h2>
                </div>
                <img src={ten} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4 | lg:relative lg:left-[26px]">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilRuler color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Creative Designs</p>
                        </h2>
                </div>
                <img src={nine} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            {/*<div className="sticky top-[90px] h-[260px] w-full bg-white  rounded-3xl ">
                <div className="w-full relative left-14 flex flex-wrap p-2 items-center justify-center my-4">
                    <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                        <svg className="svg-inline--fa fa-chalkboard-teacher text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                        <path fill="currentColor" 
                        d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"></path></svg>
                        <i class="fa-solid fa-fas fa-chalkboard-teacher text-white"></i>
                    </div>
                    <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                        <p>Social Media Management</p>
                    </h2>
                </div>  
                <img src={three} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                <div className='relative -left-3'>
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <svg className="svg-inline--fa fa-chalkboard-teacher ml-[3px] text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                            <path fill="currentColor" 
                            d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"></path></svg>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Branding</p>
                        </h2>
                    </div>  
                </div>
                <img src={five} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                <div className='relative -left-5'>
                    <div className="w-full flex relative left-11 flex-wrap p-2 items-center justify-center my-4">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilEnvelopeStar color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>E-mail Marketing</p>
                        </h2>
                    </div>  
                </div>
                <img src={seven} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                <div className='relative left-[26px]'>
                    <div className="w-full flex relative left-4 flex-wrap p-2 items-center justify-center my-4">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilArrowGrowth color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Paid Advertisements</p>
                        </h2>
                    </div>  
                </div>
                <img src={eight} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                <div className='relative left-[26px]'>
                    <div className="w-full flex relative left-0 flex-wrap p-2 items-center justify-center my-4">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilBolt color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Lead Generation</p>
                        </h2>
                    </div>  
                </div>
                <img src={six} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                <div className='relative left-[26px]'>
                    <div className="w-full flex relative left-3 flex-wrap p-2 items-center justify-center my-4">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <svg className="svg-inline--fa fa-chalkboard-teacher text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chalkboard-teacher" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z"></path></svg>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Brand Collboration</p>
                        </h2>
                    </div>  
                </div>
                <img src={eleven} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                <div className='relative left-[26px]'>
                    <div className="w-full flex relative -left-3 flex-wrap p-2 items-center justify-center my-4">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilBullseye color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Blog Services</p>
                        </h2>
                    </div>  
                </div>
                <img src={ten} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>
            <div className="sticky top-[90px] h-[260px] bg-white  rounded-3xl ">
                <div className='relative left-[26px]'>
                    <div className="w-full flex flex-wrap p-2 items-center justify-center my-4">
                        <div className="w-12 h-12 rounded-[15px] flex justify-center items-center bg-black p-3">
                            <UilRuler color='#ffffff'/>
                        </div>
                        <h2 className="text-lg font-poppins text-primary-600 px-4 font-semibold">
                            <p>Creative Designs</p>
                        </h2>
                    </div>  
                </div>
                <img src={nine} alt="Entries service 1" className=" h-full w-full object-cover rounded-br-3xl rounded-bl-3xl | sm:block"/>
            </div>*/}
        </div>
    </div>
    <div className='w-full h-36 bg-black'>

    </div>
    <div id='testimonials' className="w-full flex flex-col px-2  | sm:px-10 | md:px-12 | xl:px-32 | 2xl:px-40 | 3xl:px-60 | 4xl:px-80 z-10 bg-black py-[128px]">
        <div className="w-full flex flex-wrap justify-center mb-7"><div className="inline-flex mr-2">
            <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 576 512"><path d={starsvg} fill="currentColor"></path></svg>
            </div>
            <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 576 512"><path d={starsvg} fill="currentColor"></path></svg>
            </div>
            <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 576 512"><path d={starsvg} fill="currentColor"></path></svg>
            </div><div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 576 512"><path d={starsvg} fill="currentColor"></path></svg>
            </div>
            <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-full" viewBox="0 0 576 512"><path d={starsvg} fill="currentColor"></path></svg>
            </div>
        </div>
        <h2 className="text-xs font-poppins text-white uppercase mb-0">
                <span>Rated 5 stars on <a href="/" target="_blank" rel="noreferrer noopener" class="link link--visible text-white">Google</a></span>
        </h2>
    </div>
    <div className="w-full flex flex-wrap justify-center | lg:mx-auto lg:w-1/2 | xl:w-1/3">
        <h2 className="text-2xl | md:text-3xl | lg:text-4xl | xl:text-5xl font-poppins text-white text-center font-semibold mb-7 tracking-tight leading-tight">
            <span>Quotes that make us blush</span>
        </h2>
    </div>
    </div>
    </div>
  )
}

export default Own