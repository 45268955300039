import React from 'react'
import third from '../imgaes/co-crop.jpg'
import fourth from '../imgaes/f2-crop.jpg'
const WeAre = () => {
  return (
    <div class="w-full bg-black pt-19.5 pb-16 | sm:px-5 | md:px-10 | lg:pt-29.5 lg:pb-32">
        <div class="w-full flex flex-wrap px-2 | sm:px-10 | md:px-12 | xl:px-32 | 2xl:px-40 | 3xl:px-60 | 4xl:px-80 pt-12 | lg:pt-16">
            <div class="w-full flex flex-col px-2 | lg:w-1/2">
                <div class="w-full flex flex-col relative -ml-4 sm:-ml-10 md:-ml-12 lg:-ml-16">
                    <p class="text-xs font-poppins text-white pl-12 font-semibold leading-normal uppercase tracking-wide pb-12 | sm:pb-6">
                    About us
                    </p>
                    <div class="transition text-white w-12 h-12 flex items-center justify-center  z-10 absolute top-2 left-0">
                        <svg class="h-full w-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 64">
                            <path d="M11.997 58.473c-.183-.014-.366-.028-.549-.04-3.484-.25-6.968-.494-10.452-.752-.346-.026-.492.07-.491.425 0 .343-.01.692-.07 1.03-.076.43.058.594.503.67 2.529.431 5.066.834 7.573 1.37 2.934.626 5.84 1.38 8.758 2.078.164.039.33.07.563.12.005-.23.021-.41.01-.588-.359-5.48-.303-10.967-.053-16.457.038-.845.13-.765-.734-.88-1.289-.172-1.279-.173-1.452 1.122-.439 3.276-.887 6.55-1.333 9.826-.012.087-.04.172-.075.31-.104-.09-.178-.14-.233-.206-2.324-2.786-4.52-5.661-6.215-8.89C4.2 40.854 2.752 33.692 3.89 26.053c.92-6.18 3.427-11.709 7.268-16.662 2.086-2.69 4.51-5.043 7.175-7.156.55-.435.545-.442.11-.98-.243-.303-.5-.597-.73-.909-.242-.325-.456-.312-.774-.06-2.282 1.824-4.43 3.785-6.348 5.993-3.62 4.171-6.334 8.846-7.957 14.113C.017 28.883.469 37.206 3.788 45.35a40.15 40.15 0 0 0 5.178 9.096c.891 1.178 1.823 2.325 2.737 3.486.114.145.232.286.348.43l-.054.11Z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="w-full flex flex-col px-2 mb-6 | lg:mb-0 lg:w-7/12">
                <h1 class="text-4xl | md:text-5xl | lg:text-6xl | xl:text-8xl font-poppins font-semibold leading-tight tracking-tight pr-1/12 text-white">
                <span className='font-poppins'>Who we are</span>
                </h1>
                <div className='w-full flex text-white mt-24'>
                    <div className='w-1/2 flex justify-center items-center'>
                        <div className='h-[40] w-[40px] | lg:h-[80px] lg:w-[80px]'>
                        <img src={third} alt="pic" className='h-full w-full overflow-hidden rounded-full'/>
                        </div>
                        <div className='px-6 flex flex-col justify-center'>
                            <span className='text-sm font-poppins font-semibold | lg:text-xl'>Founder</span>
                            <div className='flex justify-between | lg:mt-4'>
                                <div>
                                <span className='text-sm font-poppins mt-3 | lg:text-lg'>Khushi</span>
                                </div>
                                <div className='mx-2 | lg:mx-4'>
                                    <a href="https://www.linkedin.com/in/khushi2003" class="w-6 h-6  flex items-center justify-center bg-white rounded-full transform | sm:mt-0 | hover:opacity-80 hover:scale-90">
                                        <div class="transition text-primary-600 h-4 w-4 flex items-center justify-center ">
                                            <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="black">
                                                </path>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/2 flex justify-center items-center'>
                        <div className='h-[40px] w-[40px] | lg:h-[80px] lg:w-[80px]'>
                        <img src={fourth} alt="pic" className='h-full w-fit overflow-hidden rounded-full'/>
                        </div>
                        <div className='px-6 flex flex-col justify-center'>
                            <span className='text-sm font-poppins font-semibold | lg:text-xl'>Co-Founder</span>
                            <div className='flex justify-between | lg:mt-4'>
                                <div>
                                <span className='text-sm font-poppins mt-3 | lg:text-lg'>Aman</span>
                                </div>
                                <div>
                                    <a href="https://www.linkedin.com/in/aman-tyagi-a715b5214" class="w-6 h-6  flex items-center justify-center bg-white rounded-full transform | sm:mt-0 | hover:opacity-80 hover:scale-90">
                                        <div class="transition text-primary-600 h-4 w-4 flex items-center justify-center ">
                                            <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="black">
                                                </path>
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full flex flex-col justify-center px-2 | lg:w-5/12">
                <span class="font-poppins text-white">Welcome to Social Up, where we redefine the digital marketing agency experience. In a bustling landscape of startups emerging daily in India, we envision a world where creativity thrives. Our mission? Empowering companies to bypass the trial-and-error cycle and connect effortlessly with their target audience. Through bespoke strategies tailored to each brand and individual, we tackle growth obstacles head-on, illuminating their path to success. Join us in illuminating your brand's brilliance today.</span>
            </div>
        </div>
        {/*<div className='w-full flex text-white mt-8'>
            <div className='w-1/2 flex'>
                <div className='h-[80px] w-[80px] rounded-full'>
                <img src={fourth} alt="pic" className='h-full w-fit overflow-hidden rounded-full' />
                </div>
                <div className='px-2 flex flex-col justify-center'>
                    <span className='text-xxl font-poppins font-semibold'>Founder</span>
                    <div className='flex justify-between mt-4'>
                        <div>
                        <span className='text-lg font-poppins mt-3'>Aman</span>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/in/aman-tyagi-a715b5214" class="w-6 h-6  flex items-center justify-center bg-white rounded-full transform | sm:mt-0 | hover:opacity-80 hover:scale-90" target="_blank">
                                <div class="transition text-primary-600 h-4 w-4 flex items-center justify-center ">
                                    <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="black">
                                        </path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-1/2 flex'>
                <div className='h-[80px] w-[80px]'>
                <img src={third} alt="pic" className='h-full w-fit overflow-hidden rounded-full'/>
                </div>
                <div className='px-10 flex flex-col justify-center'>
                    <span className='text-xl font-poppins font-semibold'>Co-Founder</span>
                    <div className='flex justify-between mt-4'>
                        <div>
                        <span className='text-lg font-poppins mt-3'>Khushi</span>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/in/khushi2003" class="w-6 h-6  flex items-center justify-center bg-white rounded-full transform | sm:mt-0 | hover:opacity-80 hover:scale-90" target="_blank">
                                <div class="transition text-primary-600 h-4 w-4 flex items-center justify-center ">
                                    <svg class="w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" fill="black">
                                        </path>
                                    </svg>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/}
    </div>
  )
}

export default WeAre