import React from 'react'
import Marquee from 'react-fast-marquee'
const Testimonial = () => {
  return (
    <div>
        <Marquee direction="left" speed={100} delay={5}>
            <div className='flex bg-black p-10'>
                <div class="w-[300px] group relative mx-8 ">
                    <div class="w-full flex flex-col relative">
                        <div class="w-full mb-7">
                            <div class="inline-flex mr-2">
                                <div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mb-8">
                            <span class="font-poppins text-white">
                            Social Up helped me scale my business from 3L+ monthly sales to 5L with their tailored campaigns and strategies. They also helped brand my account and made me try new things which were worth it! I would suggest everyone to work with them! Best of luck to Khushi & Aman!
                            </span>
                        </div>
                        <div class="w-full">
                            <div class="flex items-center">
                                <div class="mr-3 flex flex-shrink-0">
                                    <div class="flex justify-center items-center font-poppins font-medium text-md text-white rounded-full w-8 h-8 bg-red-500">S</div>
                                </div>
                                <div class="flex-col font-poppins font-medium text-xs text-white uppercase">
                                    <p class="mb-0">Shilpa Kotharia</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-[300px] group relative mx-8">
                    <div class="w-full flex flex-col relative">
                        <div class="w-full mb-7">
                            <div class="inline-flex mr-2">
                                <div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mb-8">
                            <span class="font-poppins text-white">Social Up added much-needed aesthetics to my page and even optimized the page in a way that it could target celebrities. After working with Social Up team, my page grew from a home-made food brand to a digital brand. 
                            </span>
                        </div>
                        <div class="w-full">
                            <div class="flex items-center">
                                <div class="mr-3 flex flex-shrink-0">
                                    <div class="flex justify-center items-center font-poppins font-medium text-md text-white rounded-full w-8 h-8 bg-red-500">P</div>
                                </div>
                                <div class="flex-col font-poppins font-medium text-xs text-white uppercase">
                                    <p class="mb-0">Pooja</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-[300px] group relative mx-8">
                    <div class="w-full flex flex-col relative">
                        <div class="w-full mb-7">
                            <div class="inline-flex mr-2">
                                <div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mb-8">
                            <span class="font-poppins text-white">I had a great time working with them. They have brought the best of our product with their creative marketing strategies. You should definitely choose Social Up for promoting your business digitallt.</span>
                        </div>
                        <div class="w-full">
                            <div class="flex items-center">
                                <div class="mr-3 flex flex-shrink-0">
                                    <div class="flex justify-center items-center font-poppins font-medium text-md text-white rounded-full w-8 h-8 bg-red-500">U</div>
                                </div>
                                <div class="flex-col font-poppins font-medium text-xs text-white uppercase">
                                    <p class="mb-0">Utkarsh</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-[300px] group relative mx-8">
                    <div class="w-full flex flex-col relative">
                        <div class="w-full mb-7">
                            <div class="inline-flex mr-2">
                                <div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mb-8">
                            <span class="font-poppins text-white">Social Up has helped Aries Infraventures create a dynamic digital presence. Khushi and Aman have very positive approach towards the projects. Loved working with them. Highly recommend for Real Estate businesses.</span>
                        </div>
                        <div class="w-full">
                            <div class="flex items-center">
                                <div class="mr-3 flex flex-shrink-0">
                                    <div class="flex justify-center items-center font-poppins font-medium text-md text-white rounded-full w-8 h-8 bg-red-500">V</div>
                                </div>
                                <div class="flex-col font-poppins font-medium text-xs text-white uppercase">
                                    <p class="mb-0">Vikas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-[300px] group relative mx-8">
                    <div class="w-full flex flex-col relative">
                        <div class="w-full mb-7">
                            <div class="inline-flex mr-2">
                                <div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                    </div><div class="transition text-secondary-600 h-4 w-4 flex items-center justify-center  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full h-full" viewBox="0 0 576 512"><path d="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z" fill="white"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div class="w-full mb-8">
                            <span class="font-poppins text-white">Social Up has truly elevated our online presence as an interior designing company. Their targeted advertising strategies have brought my work to a wider audience, resulting in increased client engagement. The team of Social Up has repeatedly put up excellent work and engaged with us every day</span>
                        </div>
                        <div class="w-full">
                            <div class="flex items-center">
                                <div class="mr-3 flex flex-shrink-0">
                                    <div class="flex justify-center items-center font-poppins font-medium text-md text-white rounded-full w-8 h-8 bg-red-500">S</div>
                                </div>
                                <div class="flex-col font-poppins font-medium text-xs text-white uppercase">
                                    <p class="mb-0">Sunil</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Marquee>
    </div>
  )
}

export default Testimonial