import React from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailIcon from '@mui/icons-material/Mail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Link } from 'react-router-dom'
import { starsvg } from '../utils/constant';
import { HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const NewFooter = () => {

    const { pathname } = useLocation();
    const navigate=useNavigate();
    const handlePath=()=>{
        if(pathname!=='/')  navigate('/')   
    }

  return (
    <footer className="text-white bg-black body-font">
        <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
            <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
            <a className="flex title-font font-medium items-center md:justify-start justify-center text-white">
                <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-full h-full" 
                        viewBox="0 0 576 512">
                        <path d={starsvg} 
                        fill="currentColor"
                        >
                        </path>
                    </svg>
                </div>
                <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-full h-full" 
                        viewBox="0 0 576 512">
                        <path d={starsvg} 
                        fill="currentColor"
                        >
                        </path>
                    </svg>
                </div>
                <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-full h-full" 
                        viewBox="0 0 576 512">
                        <path d={starsvg} 
                        fill="currentColor"
                        >
                        </path>
                    </svg>
                </div>
                <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-full h-full" 
                        viewBox="0 0 576 512">
                        <path d={starsvg} 
                        fill="currentColor"
                        >
                        </path>
                    </svg>
                </div>
                <div className="transition text-white h-4 w-4 flex items-center justify-center  ">
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        className="w-full h-full" 
                        viewBox="0 0 576 512">
                        <path d={starsvg} 
                        fill="currentColor"
                        >
                        </path>
                    </svg>
                </div>
                <span className="ml-1 text-sm">Rated 5 star on Google</span>
            </a>
            <h2 className="text-2xl mt-2 | lg:text-3xl | xl:text-4xl font-poppins text-center font-semibold leading-tight mb-6 | md:text-left text-secondary-600">
                <p className='text-white'>It's time to </p>
                <span className='text-white'>Social Up</span>
            </h2>
            <div className='pt-4'>
                <Link to='/contact'><button className="relative inline-flex items-center justify-center p-2 px-6 py-2 overflow-hidden font-medium text-white transition duration-300 ease-out border-2 border-white rounded-full shadow-md group">
                    <span class="absolute inset-0 flex items-center justify-center w-full h-full text-black duration-300 -translate-x-full bg-white group-hover:translate-x-0 ease">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span className="absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease">Let's Chat</span>
                    <span className="relative invisible">Let's Chat</span>
                </button></Link>
                </div>
            </div>
            <div className="flex-grow flex justify-between flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
            <div className="lg:w-1/4 md:w-1/2 w-full | lg:ml-12">
                <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">NAVIGATE</h2>
                <nav className="list-none mb-10">
                <li>
                    <Link to='/about'>
                        <a className="text-white font-poppins">About Us</a>
                    </Link>
                </li>
                <li>
                    <HashLink smooth to="#services">
                        <a onClick={handlePath} className="text-white font-poppins">Services</a>
                    </HashLink>
                </li>
                <li>
                    <HashLink smooth to='#testimonials'>
                        <a onClick={handlePath} className="text-white font-poppins">Testimonials</a>
                    </HashLink>
                </li>
                <li>
                    <a className="text-white font-poppins">Blog</a>
                </li>
                </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full | lg:mr-12">
                <h2 className="title-font font-medium text-white tracking-widest text-sm mb-3">WHAT WE DO</h2>
                <nav className="list-none mb-10">
                <li>
                    <a className="text-white font-poppins">Social Media Marketing</a>
                </li>
                <li>
                    <a className="text-white font-poppins">Social Media Management</a>
                </li>
                <li>
                    <a className="text-white font-poons">Creatore Marketing</a>
                </li>
                <li>
                    <a className="text-white font-poppins">Personal Branding</a>
                </li>
                <li>
                    <a className="text-white font-poppins">Paid Advertisement</a>
                </li>
                <li>
                    <a className="text-white font-poppins">Blog Services</a>
                </li>
                <li>
                    <a className="text-white font-poppins">Lead Generation</a>
                </li>
                <li>
                    <a className="text-white font-poppins">Brand Collaboration</a>
                </li>
                </nav>
            </div>
            <div className="lg:w-1/4 md:w-1/2 w-full ">
            <div className="flex flex-col items-center | md:items-start xl:ml-auto">
                <h2 className="text-white text-xs font-poppins text-primary-300 text-center uppercase mb-4 | md:mb-4 md:text-left">
                    Chat
                </h2>
                <a href="tel:9927521561" className="font-poppins font-normal text-base text-white transition mb-0.5">
                    <span><LocalPhoneIcon fontSize='large'/></span>
                    <span>+91 9927521561</span>
                </a>
                <a href="mailto:hello@socialupmarketing.in" className="flex justify-center items-center font-poppins pt-4 my-1 font-normal text-base text-white transition">
                    <span><MailIcon fontSize='large'/></span>
                    <span className='px-2'>hello@socialupmarketing.in</span>
                </a>
                <div className="font-poppins pt-4 my-1 font-normal text-base text-white transition">
                    <a href="https://www.linkedin.com/company/social-up-marketing/">
                    <LinkedInIcon fontSize='large' color='primary'/>
                    </a>
                    <a href="https://www.instagram.com/socialupmarketing.in?igsh=MXdhNDR0eHAxMjZybQ==" className="mx-2">
                    <InstagramIcon fontSize='large'/>
                    </a>
                    <a href="https://www.facebook.com/socialupmarketing.in?mibextid=ZbWKwL">
                    <FacebookIcon fontSize='large'/>
                    </a>
                </div>
            </div>
            </div>
            </div>
        </div>
        {/*<div className="bg-black">
            <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
            <p className="text-gray-500 text-sm text-center sm:text-left">© 2024 Manish —
                <a href="/" rel="noopener noreferrer" className="text-white ml-1" target="_blank">@pardhan</a>
            </p>
            </div>
        </div>*/}
    </footer>

  )
}

export default NewFooter