import React from 'react'
import three from '../imgaes/thired.jpg'
import four from '../imgaes/third-2.jpg'
const ThirdContainer = () => {
  return (
    <div className='w-full flex flex-col-reverse | lg:flex lg:flex-row'>
        <div className='w-full pl-[40px] | lg:w-1/2 lg:pl-[100px]'>
            <div className='px-4'>
            <div className="w-full flex flex-col relative -ml-4 sm:-ml-10 md:-ml-12 lg:-ml-16">
                <p className="text-xs font-poppins text-primary-600 pl-12 font-semibold leading-normal uppercase tracking-wide pb-12 | sm:pb-6">
                    Our Approach
                </p>
                <div className="transition text-primary-600 w-12 h-12 flex items-center justify-center  z-10 absolute top-2 left-0">
                    <svg className="h-full w-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 64"><path d="M11.997 58.473c-.183-.014-.366-.028-.549-.04-3.484-.25-6.968-.494-10.452-.752-.346-.026-.492.07-.491.425 0 .343-.01.692-.07 1.03-.076.43.058.594.503.67 2.529.431 5.066.834 7.573 1.37 2.934.626 5.84 1.38 8.758 2.078.164.039.33.07.563.12.005-.23.021-.41.01-.588-.359-5.48-.303-10.967-.053-16.457.038-.845.13-.765-.734-.88-1.289-.172-1.279-.173-1.452 1.122-.439 3.276-.887 6.55-1.333 9.826-.012.087-.04.172-.075.31-.104-.09-.178-.14-.233-.206-2.324-2.786-4.52-5.661-6.215-8.89C4.2 40.854 2.752 33.692 3.89 26.053c.92-6.18 3.427-11.709 7.268-16.662 2.086-2.69 4.51-5.043 7.175-7.156.55-.435.545-.442.11-.98-.243-.303-.5-.597-.73-.909-.242-.325-.456-.312-.774-.06-2.282 1.824-4.43 3.785-6.348 5.993-3.62 4.171-6.334 8.846-7.957 14.113C.017 28.883.469 37.206 3.788 45.35a40.15 40.15 0 0 0 5.178 9.096c.891 1.178 1.823 2.325 2.737 3.486.114.145.232.286.348.43l-.054.11Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <h2 className="text-2xl | xl:text-3xl font-poppins font-semibold text-primary-600 leading-tight tracking-tight mb-10 | lg:pr-2/12">
                <span>Get your brand noticed, engaged, and loved. We're the 360° experts.</span>
            </h2>
            <div className="w-full lg:px-2/12">
                <div className="w-full mb-10">
                    <span className="font-poppins text-primary-600">India's startup scene is exploding, and we're here to fuel the fire! Whether you're just a spark of an idea or a rising star, we're your go-to partner for launching and marketing your brand, from A to Z. Forget cookie-cutter solutions. We know every startup is unique, with its own values, vision, and voice</span>
                </div>
            </div>
        </div>  
        </div>
        <div className='flex w-full relative | lg:w-1/2 lg:ml-10'>
            <div className='w-full object-cover p-6 | lg:w-1/2 lg:p-0'>
                <img src={three} alt='pic' className='h-[300px] w-full rounded-[20px] | lg:h-[407px] lg:w-[338px]'/>
            </div>
            <div className='w-1/2 hidden | lg:block lg:mt-52 lg:pl-4'>
                <img src={four} alt='pic' className='h-[200px] rounded-[20px] | lg:h-[361px] lg:w-full '/>
            </div>
        </div>
    </div>
  )
}

export default ThirdContainer