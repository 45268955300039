import React from 'react'
import Marquee from 'react-fast-marquee'

const MoreOne = () => {
  return (
    <div className='bg-white text-black w-full mt-6 | lg:mt-12'>
      <Marquee direction="right" speed={50} delay={5} className='overflow-hidden'>
          <div className='w-full flex items-center p-2'>
              <p className='text-4xl font-semibold  font-poppins w-full | lg:text-8xl'>We get you heard *</p>
          </div> 
          <div className='w-full flex items-center p-2'>
              <p className='text-4xl font-semibold font-poppins w-full | lg:text-8xl'>We get you heard*</p>
          </div>
      </Marquee>
    </div>
  )
}

export default MoreOne