import React from 'react'

const WhoThird = () => {
  return (
    <div class="w-full flex flex-wrap relative px-2 -mt-80 mb-14 | sm:px-10 | md:px-12 | xl:px-32 | 2xl:px-40 | 3xl:px-60 | 4xl:px-80">
        <div class="w-full flex flex-col justify-center bg-black rounded-3xl relative px-8 py-12 | sm:px-12 sm:py-14 | md:py-16 md:px-14 | lg:py-28 lg:px-24">
            <div class="w-16 h-20 mb-10 z-10">
                <div class="transition text-white w-16 h-20 flex items-center justify-center  relative">
                    <svg class="w-full h-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 64"><path d="M26.8 0h10.8v18.6h-9.8c-2.267 0-3.867.467-4.8 1.4-.933.8-1.4 2.4-1.4 4.8v3.4h17v35H0V25C0 8.333 8.933 0 26.8 0Zm48.8 0h10.6v18.6h-9.8c-2.133 0-3.667.467-4.6 1.4-.933.8-1.4 2.4-1.4 4.8v3.4h17v35H48.6V25c0-8.267 2.333-14.467 7-18.6C60.267 2.133 66.933 0 75.6 0Z" fill="currentColor"></path></svg>
                </div>
            </div>
            <div class="w-full mb-14 z-10">
                <p class="text-xl | md:text-4xl | lg:text-5xl | xl:text-6xl font-poppins font-semibold leading-tight tracking-tight text-white">
                <span>Crafting success stories through innovative strategies. Your brand's journey, our canvas. Let's paint a masterpiece together</span>
                </p>
            </div>
            {/*<div class="w-full z-10">
                <div class="flex items-center">
                    <div class="flex-col font-poppins font-medium text-xs text-white uppercase">
                        <p class="mb-0">Jordan Stachini</p><p class="text-primary-300 mb-0">Founder</p>
                    </div>
                </div>
            </div>*/}
        </div>
    </div>
  )
}

export default WhoThird