import React from 'react'
import Marquee from 'react-fast-marquee'
import MyComponent from '../utils/MyComponent'
import one from '../imgaes/Aries infraventure.jpg'
import two from '../imgaes/IIT Kanpur.png'
import three from '../imgaes/INDIGG.jpg'
import four from '../imgaes/Daga-Infratech.png'
import five from '../imgaes/Sleepy owl.webp'
import seven from '../imgaes/techlog.jpg'
import ten from '../imgaes/Logues.png'
import eleven from '../imgaes/Balaji-Logo.png'
import tweleve from '../imgaes/BOB_logo.avif'
import fourteen from '../imgaes/AFK Gaming.svg'
import seventeen from '../imgaes/cropped-FriskyDesigns-Logo.png'
import eighteen from '../imgaes/cropped-tharmacol-logo.png'

const Partner = () => {
  return (
    <div className='w-full overflow-x-hidden flex my-6 | lg:my-24'>
        <div className='p-1'>
            <p className='font-bold font-poppins w-24 text-sm | lg:w-48 lg:text-lg'>SOME OF THE FINE FOLKS</p>
            <p className='font-bold font-poppins w-24 text-sm | lg:w-48 lg:text-lg'>WE'VE WORKED WITH</p>
        </div>
        <div>
            <Marquee direction="right" speed={100} delay={5}>
                <MyComponent data={one}/>
                <MyComponent data={two}/>
                <MyComponent data={three}/>
                <MyComponent data={four}/>
                <MyComponent data={five}/>
                <MyComponent data={seven}/>
                <MyComponent data={ten}/>
                <MyComponent data={eleven}/>
                <MyComponent data={tweleve}/>
                <MyComponent data={fourteen}/>
                <MyComponent data={seventeen}/>
                <MyComponent data={eighteen}/>
            </Marquee>
        </div>
    </div>
  )
}

export default Partner