import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

const FirstContainer = () => {
  return (
    <div className='bg-black relative p-3 | lg:p-10'>
      <div className='bg-first bg-first-opacity-30 bg-cover mt-4 bg-no-repeat w-full h-[300px] rounded-lg | lg:w-full lg:h-[600px]'>
        <div className='bg-first-opacity-50 rounded-lg w-full h-full'>
        </div>  
      </div>
      <div>
        <div className="flex-col absolute top-6 left-6 | lg:top-16 lg:left-16">
          <p className='text-white text-4xl font-semibold px-2 pt-12 font-poppins pl-1 | lg:pt-40 lg:text-8xl lg:pl-6'>Where  brands</p>
          <p className='text-white text-4xl font-semibold px-2 pt-4 pl-1 font-poppins | lg:text-8xl lg:pl-6'>go viral</p>
          <div className='flex mt-10'>
            <div>
              <Link to='/about'><a href="#_" class="ml-6 relative  items-center justify-start inline-block px-5 py-3 overflow-hidden font-bold rounded-full group">
                <span class="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
                <span class="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-white opacity-100 group-hover:-translate-x-8"></span>
                <span class="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-gray-900">Who we are</span>
                <span class="absolute inset-0 border-2 border-white rounded-full"></span>
              </a></Link>
            </div>
            <div>
              <HashLink smooth to="#services"><a href="#_" class="ml-6 relative  items-center justify-start inline-block px-5 py-3 overflow-hidden font-bold rounded-full group">
                <span class="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
                <span class="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-white opacity-100 group-hover:-translate-x-8"></span>
                <span class="relative w-full text-left text-white transition-colors duration-200 ease-in-out group-hover:text-gray-900">Services</span>
                <span class="absolute inset-0 border-2 border-white rounded-full"></span>
              </a></HashLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirstContainer