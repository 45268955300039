import React from 'react'

const HowThird = () => {
  return (
    <div class="w-full flex flex-wrap bg-black px-2 | sm:px-10 | md:px-12 | xl:px-32 | 2xl:px-40 | 3xl:px-60 | 4xl:px-80 py-16 | lg:py-32">
        <div class="w-full flex flex-wrap px-2">
            <div class="w-full flex justify-center">
                <div class="w-auto inline-flex relative pt-16 -mt-16">
                    <h2 class="text-2xl | md:text-3xl | lg:text-4xl | xl:text-5xl font-poppins text-white font-semibold pb-10 leading-tight tracking-tight pr-16 text-center">
                    <span>How we work</span>
                    </h2>
                    <div class="transition text-white w-14 h-14 flex items-center justify-center  z-10 absolute top-4 right-10">
                    <svg class="w-full h-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63 26"><path d="m58.16 18.3.09-.543c.58-3.444 1.164-6.889 1.735-10.334.057-.343.184-.462.529-.378.333.082.675.153 1.017.175.436.028.564.197.533.647-.177 2.56-.385 5.12-.455 7.682-.083 3-.036 6.001-.047 9.002 0 .168-.008.337-.015.575-.224-.05-.404-.076-.574-.13-5.241-1.64-10.586-2.88-15.98-3.932-.83-.162-.775-.054-.683-.92.138-1.294.134-1.284 1.434-1.147 3.287.346 6.574.683 9.862 1.022.087.009.177 0 .32 0-.064-.122-.095-.206-.145-.275-2.16-2.915-4.436-5.727-7.174-8.136-5.73-5.042-12.348-8.137-20.04-8.834-6.223-.563-12.187.57-17.906 3.134-3.106 1.393-5.965 3.193-8.646 5.285-.553.43-.558.425-.98-.124-.236-.308-.461-.627-.71-.925-.259-.311-.196-.517.125-.765C2.76 7.59 5.172 5.965 7.77 4.623 12.679 2.088 17.862.553 23.363.219c8.869-.54 16.85 1.862 23.981 7.008a40.151 40.151 0 0 1 7.618 7.178c.935 1.143 1.83 2.32 2.742 3.481.114.145.224.294.336.44l.12-.027Z" fill="currentColor"></path></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full px-2 grid grid-cols-1 gap-5 | md:grid-col-2 | lg:grid-cols-3">
            <div class="w-full flex flex-col rounded-3xl bg-white px-8 pt-7 pb-11 | sm:px-10">
                <div class="w-full flex flex-wrap mb-12 font-poppins font-medium text-xs text-primary-600 uppercase">
                    01
                </div>
                <div class="w-full flex flex-col mb-auto">
                    <div class="w-5 h-5 mb-6">
                        <svg class="svg-inline--fa fa-bullseye text-primary-600" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bullseye" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M448 256A192 192 0 1 0 64 256a192 192 0 1 0 384 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 80a80 80 0 1 0 0-160 80 80 0 1 0 0 160zm0-224a144 144 0 1 1 0 288 144 144 0 1 1 0-288zM224 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"></path></svg>
                        <i class="fa-solid fa-fas fa-bullseye text-primary-600"></i>
                    </div>
                    <h2 class="text-xl | md:text-1xl | lg:text-2xl font-poppins font-semibold leading-tight tracking-tight mb-6 text-primary-600">
                        <span>Right Solution</span>
                    </h2>
                    <span class="font-poppins text-primary-600">We build our plans around what you need, not what we offer</span>
                </div>
            </div>
            <div class="w-full flex flex-col rounded-3xl bg-white px-8 pt-7 pb-11 | sm:px-10">
                <div class="w-full flex flex-wrap mb-12 font-poppins font-medium text-xs text-primary-600 uppercase">
                    02
                </div>
                <div class="w-full flex flex-col mb-auto">
                    <div class="w-5 h-5 mb-6">
                        <svg class="svg-inline--fa fa-heart text-primary-600" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"></path></svg>
                    </div>
                    <h2 class="text-xl | md:text-1xl | lg:text-2xl font-poppins font-semibold leading-tight tracking-tight mb-6 text-primary-600">
                        <span>Good Vibes</span>
                    </h2>
                    <span class="font-poppins text-primary-600">We only work with people we like and have great rapport with</span>
                </div>
            </div>
            <div class="w-full flex flex-col rounded-3xl bg-white px-8 pt-7 pb-11 | sm:px-10">
                <div class="w-full flex flex-wrap mb-12 font-poppins font-medium text-xs text-primary-600 uppercase">
                    03
                </div>
                <div class="w-full flex flex-col mb-auto">
                    <div class="w-5 h-5 mb-6">
                        <svg class="svg-inline--fa fa-pound-sign text-primary-600" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pound-sign" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M112 160.4c0-35.5 28.8-64.4 64.4-64.4c6.9 0 13.8 1.1 20.4 3.3l81.2 27.1c16.8 5.6 34.9-3.5 40.5-20.2s-3.5-34.9-20.2-40.5L217 38.6c-13.1-4.4-26.8-6.6-40.6-6.6C105.5 32 48 89.5 48 160.4V224H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H48v44.5c0 17.4-4.7 34.5-13.7 49.4L4.6 431.5c-5.9 9.9-6.1 22.2-.4 32.2S20.5 480 32 480H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H88.5l.7-1.1C104.1 390 112 361.5 112 332.5V288H224c17.7 0 32-14.3 32-32s-14.3-32-32-32H112V160.4z"></path></svg>
                        <i class="fa-solid fa-fas fa-pound-sign text-primary-600"></i>
                    </div>
                    <h2 class="text-xl | md:text-1xl | lg:text-2xl font-poppins font-semibold leading-tight tracking-tight mb-6 text-primary-600">
                        <span>No Fluff Pricing</span>
                    </h2>
                        <span class="font-poppins text-primary-600">We offer cost-effective retainers as well as one-off projects</span>
                    </div>
                </div>
            </div>
        <div class="w-full flex flex-col items-center mb-80">

        </div>
    </div>
  )
}

export default HowThird