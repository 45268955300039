import React from 'react'
import WeAre from './WeAre'
import HowThird from '../Pages/HowThird'
import WhoThird from './WhoThird'
import NewNavbar from '../Responsive Navbar/NewNavbar'
import NewFooter from '../Responsive Navbar/NewFooter'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'
const WhoMain = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
        <NewNavbar/>
        <WeAre/>
        <HowThird/>
        <WhoThird/>
        <NewFooter/>
    </div>
  )
}

export default WhoMain