import React from 'react'
import Marquee from 'react-fast-marquee'

const MoreTwo = () => {
  return (
    <div className='bg-white text-black mt-4'>
      <Marquee direction="left" className='overflow-hidden' speed={50} delay={5}>
          <div className='w-full flex items-center h-[100px] m-2'>
              <p className='text-4xl font-semibold h-full font-poppins | lg:text-8xl'>Don't blend in * </p>
          </div> 
          <div className='w-full flex items-center h-[100px] m-2'>
              <p className='text-4xl font-semibold h-full font-poppins | lg:text-8xl'>Don't blend in * </p>
          </div>
      </Marquee>
    </div>
  )
}

export default MoreTwo