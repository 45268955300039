import React from 'react'
import NewNavbar from '../Responsive Navbar/NewNavbar'
import Contact from '../Components/Contact'
import ExtraFunc from '../utils/ExtraFunc'
import NewFooter from '../Responsive Navbar/NewFooter'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react'

const ContactMain = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
        <NewNavbar/>
        <Contact/>
        <ExtraFunc/>
        <NewFooter/>
    </div>
  )
}

export default ContactMain