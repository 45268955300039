import React from 'react'
import { FaCircleArrowDown } from "react-icons/fa6";
import { FaCircleArrowUp } from "react-icons/fa6";
import { Collapse } from 'react-collapse';
const Accordion = ({open,toggle,title,desc}) => {
  return (
    <div className='pt-[10px] bg-slate-300 rounded-lg mb-5'>
      <div 
        className='bg-slate-300 rounded-xl py-[25px] px-[50px] flex justify-between items-center cursor-pointer'
        onClick={toggle}
      >
        <p className='text-[22px] font-poppins font-semibold'>{title}</p>
        <div className='text-[30px] rounded-xl'>
          {open ? <FaCircleArrowUp/> : <FaCircleArrowDown/>}
        </div>
      </div>
      <Collapse isOpened={open}>
        <div className='bg-slate-300 rounded-xl font-poppins mb-5 px-[50px] pb-[20px]'>
          {desc}
        </div>
      </Collapse>
    </div>
  )
}

export default Accordion