import React, { useState } from 'react'
import { checkValidData } from '../utils/Validate'
import { useRef } from 'react';

const Contact = () => {

    const mail=useRef(null);
    const number=useRef(null);
    const[errorMessage,setErrorMessage]=useState('')

    const handlButtonClick = () =>{
        console.log(mail);
        console.log(number)

        const message=checkValidData(mail.current.value,number.current.value);
        setErrorMessage(message)
    }
    const[userInfo,setUserInfo]=useState({
        username:"",
        company:"",
        email:"",
        phone:"",
        query:""
    })
    let name,value;
    const getData = (e) =>{
        name=e.target.name;
        value=e.target.value;

        setUserInfo({...userInfo, [name]:value});
    }

    const submitData = async (event) =>{
        event.preventDefault();
        const{ username, company,email, phone, query }=userInfo;
        const res= await fetch(
            'https://social-up-marketing-default-rtdb.firebaseio.com/UserData.json',
            {
                method:"POST",
                headers:{
                    "Content-Type": "application/json",
                },
                body:JSON.stringify({
                    username,
                    company,
                    email,
                    phone,
                    query,
                })
            },
        );

        console.log(res)

        if(res){
            alert('Message Sent')
            setUserInfo({
                username: '',
                company: '',
                email: '',
                phone: '',
                query: '',
              });
        } 
        else{
            alert('Please fill the form correctly')
        }
    };

  return (
    <div className='flex flex-col bg-black | lg:flex lg:flex-row lg:justify-evenly p-6'>
        <div className='w-1/2 pt-6 | lg:pl-10'>
            {/*<div className='text-white' >
                <p className='text-6xl font-medium font-poppins'>Let's Chat</p>
            </div>*/}
            <div className="w-auto inline-flex relative ">
                <h1 className="text-4xl | md:text-5xl | lg:text-4xl | xl:text-6xl font-poppins text-white font-medium pb-10 leading-tight tracking-tight pr-16 mb-0">
                <span>Let’s Chat</span>
                </h1>
                <div class="transition text-white w-16 h-16 flex items-center justify-center  z-10 absolute top-1/2 transform -translate-y-1/2 -right-12 -rotate-45">
                    <svg className="w-full h-full" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 64">
                    <path d="m7.611 58.543.548-.04c3.484-.249 6.97-.494 10.452-.752.347-.026.493.07.492.425 0 .343.01.692.07 1.03.076.43-.058.594-.503.67-2.529.432-5.066.834-7.573 1.37-2.934.627-5.84 1.38-8.758 2.078-.164.04-.33.07-.563.12-.005-.23-.021-.41-.01-.588.359-5.48.303-10.967.053-16.457-.038-.844-.13-.765.734-.88 1.289-.171 1.279-.172 1.452 1.122.439 3.276.887 6.55 1.333 9.826.012.087.04.172.075.311.104-.091.178-.14.233-.206 2.324-2.787 4.52-5.662 6.215-8.891 3.548-6.757 4.995-13.918 3.857-21.557-.92-6.181-3.427-11.71-7.268-16.663-2.086-2.69-4.51-5.043-7.175-7.155-.55-.436-.545-.442-.111-.981.244-.303.5-.596.732-.909.24-.324.455-.312.772-.059C4.951 2.181 7.1 4.141 9.017 6.35c3.62 4.17 6.334 8.846 7.957 14.113 2.617 8.49 2.165 16.813-1.154 24.957a40.149 40.149 0 0 1-5.178 9.096c-.891 1.178-1.823 2.325-2.737 3.486-.114.145-.232.287-.349.43l.055.11v.001Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <div>
                <span className='flex text-white flex-wrap text-4xl font-medium font-poppins | lg:pt-4'>It won’t cost you a penny. Literally. Talk really is cheap.
                </span>
            </div>
            <div className="w-full flex items-center pt-16">
                <div className="flex w-4 h-4 text-base mr-3">
                    <svg className="svg-inline--fa fa-paper-plane text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="paper-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"></path></svg>
                    </div>
                    <a href="mailto:hello@socialupmarketing.in" className="font-poppins font-medium text-lg text-white transition tracking-tight leading-tight">
                    <span className="link link--white">
                    hello@socialupmarketing.in
                    </span>
                    </a>
                </div>
                <div className="w-full flex items-center mt-3">
                    <div className="flex w-4 h-4 text-base mr-3">
                    <svg className="svg-inline--fa fa-phone text-white" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path></svg>
                    </div>
                <a href="tel:9927521561" className="font-poppins font-medium text-lg text-white transition tracking-tight leading-tight">
                <span className="link link--white">
                +91 9927521561
                </span>
                </a>
            </div>
        </div>
        <div className='pt-4'>
            <form onSubmit={(e)=>e.preventDefault()} method='POST'>
                <div className="px-2 mb-5 w-full">
                    <label for="form-input-name" className="block font-normal font-poppins text-white text-base leading-loose tracking-tight mb-1">
                        What’s your name?
                        <span className="text-white ml-px">*</span>
                    </label>
                    <input 
                        type="text" 
                        name="username" 
                        id="form-input-name" 
                        className="transition-all appearance-none block w-full bg-transparent text-white border-b border-primary-500 rounded-none px-0 pt-5 pb-4 h-12 shadow-none leading-tight placeholder-primary-400 placeholder-opacity-100 hover:border-primary-400 focus:outline-none" 
                        placeholder="Full name" 
                        value={userInfo.username}
                        onChange={getData}
                    />
                </div>
                <div className="px-2 mb-5 w-full ">
                    <label for="form-input-companyName" className="block font-normal font-poppins text-white text-base leading-loose tracking-tight mb-1">
                    What's your company name?
                    <span className="text-white ml-px">*</span>
                    </label>
                    <input 
                        type="text" 
                        name="company" 
                        id="form-input-companyName" 
                        className="transition-all appearance-none block w-full bg-transparent text-white border-b border-primary-500 rounded-none px-0 pt-5 pb-4 h-12 shadow-none leading-tight placeholder-primary-400 placeholder-opacity-100 hover:border-primary-400 focus:outline-none" 
                        placeholder="Company name"
                        value={userInfo.company}
                        onChange={getData}
                    />
                </div>
                <div className="px-2 mb-5 w-full ">
                    <label for="form-input-email" className="block font-normal font-poppins text-white text-base leading-loose tracking-tight mb-1">
                    Your email?
                    <span className="text-white ml-px">*</span>
                    </label>
                    <input 
                        type="email" 
                        name="email" 
                        ref={mail}
                        id="form-input-email" 
                        className=" transition-all appearance-none block w-full bg-transparent text-white border-b border-primary-500 rounded-none px-0 pt-5 pb-4 h-12 shadow-none leading-tight placeholder-primary-400 placeholder-opacity-100 hover:border-primary-400 focus:outline-none" 
                        placeholder="Email address"
                        value={userInfo.email}
                        onChange={getData}
                    />
                </div>
                <div className="px-2 mb-5 w-full">
                    <label for="form-input-homePhone" className="block font-normal font-poppins text-white text-base leading-loose tracking-tight mb-1">
                    Your number?
                    <span className="text-white ml-px">*</span>
                    </label>
                    <input 
                        type="text" 
                        name="phone" 
                        ref={number}
                        id="form-input-homePhone" 
                        className="transition-all appearance-none block w-full bg-transparent text-white border-b border-primary-50 rounded-none px-0 pt-5 pb-4 h-12 shadow-none leading-tight placeholder-primary-400 placeholder-opacity-100 hover:border-primary-400 focus:outline-none" 
                        placeholder="Phone number" 
                        value={userInfo.phone}
                        onChange={getData}
                    />
                </div>
                <div className="px-2 mb-5">
                    <label for="form-input-message" className="block font-normal font-poppins text-white text-base leading-loose tracking-tight mb-1">
                    How can we help?
                    <span className="text-white ml-px">*</span>
                    </label>
                    <textarea 
                        type="textarea" 
                        name="query" 
                        id="form-input-message" 
                        className=" transition-all appearance-non block w-full bg-transparent text-white border-b border-primary-500 rounded-none px-0 pt-5 pb-4 shadow-none leading-tight placeholder-primary-400 placeholder-opacity-100 hover:border-primary-400 focus:outline-none" 
                        placeholder="Tell us a little bit about your business, and what you are looking for. "
                        value={userInfo.query}
                        onChange={getData}
                    >
                    </textarea>
                </div>
                <div className="font-normal font-poppins text-white text-sm tracking-tight">
                By submitting this form you accept the 
                <a className="link link--visible" href="privacy-policy" target="_blank">Privacy Policy</a> of this site, and you’re a normal person - not a weirdo.
                <div className='md:block hidden pt-4'>
                {/*<a href="#_" type='submit' class="relative inline-flex items-center justify-center p-2 px-6 py-2 overflow-hidden font-medium text-white transition duration-300 ease-out border-2 border-white rounded-full shadow-md group">
                    <span class="absolute inset-0 flex items-center justify-center w-full h-full text-black duration-300 -translate-x-full bg-white group-hover:translate-x-0 ease">
                    <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span className="absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease">Send Message</span>
                    <span className="relative invisible">Send message</span>
                </a>*/}
                <button type="submit" onClick={submitData} className="relative inline-flex items-center justify-center p-2 px-6 py-2 overflow-hidden font-medium text-white transition duration-300 ease-out border-2 border-white rounded-full shadow-md group">
                    <span class="absolute inset-0 flex items-center justify-center w-full h-full text-black duration-300 -translate-x-full bg-white group-hover:translate-x-0 ease">
                        <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                    </span>
                    <span className="absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease">Send Message</span>
                    <span className="relative invisible">Send message</span>
                </button>
                </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default Contact