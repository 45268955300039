
export const firstimg="https://co-andco.transforms.svdcdn.com/production/images/Jordan-and-Sophie.jpeg?w=6000&h=4000&q=100&auto=format&fit=crop&dm=1694511728&s=9ba80aa3d75f1c05be5d8ecc2b6b011e"

export const firssvg="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z";
export const secondsvg="M163.9 136.9c-29.4-29.8-29.4-78.2 0-108s77-29.8 106.4 0l17.7 18 17.7-18c29.4-29.8 77-29.8 106.4 0s29.4 78.2 0 108L310.5 240.1c-6.2 6.3-14.3 9.4-22.5 9.4s-16.3-3.1-22.5-9.4L163.9 136.9zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z";
export const thirdsvg="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z";
export const fourthsvg="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z";
export const fifithsvg="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z";
export const sixthsvg="M32 0C19.1 0 7.4 7.8 2.4 19.8s-2.2 25.7 6.9 34.9L224 269.3V448H160c-17.7 0-32 14.3-32 32s14.3 32 32 32h96 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H288V269.3L502.6 54.6c9.2-9.2 11.9-22.9 6.9-34.9S492.9 0 480 0H32zM173.3 128l-64-64H402.7l-64 64H173.3z";

export const querydata=[
    {
        title:"Why is digital marketing important?",
        desc:"Digital marketing is crucial because it connects you with customers where they spend time online, boosting reach, engagement, and sales."
    },
    {
        title:"How can I increase revenue from marketing? ",
        desc:"Boost revenue with targeted campaigns, strong online presence, and data-driven optimization. Social Up can help!"
    },
    {
        title:"What are paid advertisements?",
        desc:"Paid ads like search ads and social media ads let you target specific audiences, driving qualified leads and boosting sales."
    },
    {
        title:"I have a logo, what else is needed for branding? ",
        desc:"Your logo is a start, but branding needs a consistent voice, message, and visuals across all platforms. Let Social Up craft your story."
    },
    {
        title:"How can I create strong digital marketing strategies?",
        desc:"Building winning digital strategies requires understanding your audience, setting goals, and using the right mix of tactics. Partner with Social Up for a customized plan."
    }
]

export const starsvg="m288.1 0 86.5 164 182.7 31.6L428 328.5 454.4 512l-166.3-81.8L121.7 512l26.4-183.5L18.9 195.6 201.5 164 288.1 0z";

export const rlink="https://co-andco.transforms.svdcdn.com/production/images/LinkedIn-employee-strategy.jpg?w=1800&amp;h=1350&amp;q=80&amp;fm=webp&amp;fit=crop&amp;crop=focalpoint&amp;fp-x=0.5&amp;fp-y=0.5&amp;dm=1707148756&amp;s=e0e72b75eea6ebc9464b656af5114dc6"

export const slogans=[
    {
        text:"It's time to Social Up"
    },
    {
        text:"Don't blend in"
    }
];

export const slogan=[
    {
        text:"Click. Convert. Conquer. Repeat. It's that simple."
    }
]
