import React, { useState } from 'react'
import { querydata } from '../utils/constant'
import Accordion from '../utils/Accordion'
const QueryContainer = () => {
  const[open,setOpen]=useState(false);
  const toggle=(index)=>{
    if(open===index){
      return(setOpen(null))
    }
    setOpen(index)
  }
  return (
    <div className='flex flex-col relative p-4 | lg:flex lg:flex-row md:mx-[128px]'>
      <div className='w-full | lg:w-1/2 '>
        <div className="transition text-primary-600 w-12 h-12 flex items-center justify-center  z-10 absolute top-2 left-0">
            <svg className="h-full w-full" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 64"><path d="M11.997 58.473c-.183-.014-.366-.028-.549-.04-3.484-.25-6.968-.494-10.452-.752-.346-.026-.492.07-.491.425 0 .343-.01.692-.07 1.03-.076.43.058.594.503.67 2.529.431 5.066.834 7.573 1.37 2.934.626 5.84 1.38 8.758 2.078.164.039.33.07.563.12.005-.23.021-.41.01-.588-.359-5.48-.303-10.967-.053-16.457.038-.845.13-.765-.734-.88-1.289-.172-1.279-.173-1.452 1.122-.439 3.276-.887 6.55-1.333 9.826-.012.087-.04.172-.075.31-.104-.09-.178-.14-.233-.206-2.324-2.786-4.52-5.661-6.215-8.89C4.2 40.854 2.752 33.692 3.89 26.053c.92-6.18 3.427-11.709 7.268-16.662 2.086-2.69 4.51-5.043 7.175-7.156.55-.435.545-.442.11-.98-.243-.303-.5-.597-.73-.909-.242-.325-.456-.312-.774-.06-2.282 1.824-4.43 3.785-6.348 5.993-3.62 4.171-6.334 8.846-7.957 14.113C.017 28.883.469 37.206 3.788 45.35a40.15 40.15 0 0 0 5.178 9.096c.891 1.178 1.823 2.325 2.737 3.486.114.145.232.286.348.43l-.054.11Z" fill="currentColor"></path>
            </svg>
        </div>
        <p className='absolute font-poppins top-0 left-10'>IN THE WORDS OF BEYONCE</p>
        <p className='absolute font-poppins left-14 top-10 font-bold text-primary-600 text-3xl'>Question?</p>
        <div>
          <a href="#_" class="ml-2 absolute top-24 left-14 items-center justify-start inline-block px-5 py-3 overflow-hidden font-bold rounded-full group">
            <span class="w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]"></span>
            <span class="absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-black border-black opacity-100 group-hover:-translate-x-8"></span>
            <span class="relative w-full text-left text-black transition-colors duration-200 ease-in-out group-hover:text-white">View all FAQs</span>
            <span class="absolute inset-0 border-2 border-black rounded-full"></span>
          </a>
        </div>
      </div>
      <div className='w-full mt-48 | lg:w-1/2 lg:mt-0'>
      {querydata.map((data,index)=>{
        return (<Accordion key={index} open={index===open} title={data.title} desc={data.desc}
        toggle={()=>toggle(index)}/>)
      })}
    </div>
    </div>
  )
}

export default QueryContainer